import { getItem } from 'service/storage.service';
import { Claim, UsersClaims } from 'shared-types';
import hasPermission, { getPermission } from 'utils/permission.utils';
import { customBoolDict, stringAsBool } from 'utils/text';

const FeaturesClaims = {
  UNBLOCK_PAYMENT: 'unblock.payment',
  ...UsersClaims,
};

export default {
  creditorAutoComplete: true,
  paycheck: (userClaims: Claim[] | undefined): boolean => {
    if (getItem('payslip.view') === 'true') return true;
    return hasPermission(userClaims, [UsersClaims.PAYSLIP_VIEW]);
  },
  imageLogo: (userClaims: Claim[] | undefined): string | null => {
    if (getItem(UsersClaims.OFFICE_LOGO_URL)) return getItem(UsersClaims.OFFICE_LOGO_URL);
    return getPermission(userClaims, UsersClaims.OFFICE_LOGO_URL);
  },
};

const activateViaValue =
  (userClaims: Claim[] | undefined) => (claim: keyof typeof FeaturesClaims) => {
    if (!userClaims) return false;
    const lsClaim = getItem(FeaturesClaims[claim]);
    if (lsClaim) return stringAsBool(String(lsClaim), true, true, customBoolDict);
    const claimValue = userClaims.find(c => c.atributo === FeaturesClaims[claim]);
    if (!claimValue) return false;

    return stringAsBool(String(claimValue.valor), true, true, customBoolDict);
  };

const hasClaim = (userClaims: Claim[] | undefined) => (claim: keyof typeof FeaturesClaims) => {
  const permission = getPermission(userClaims, UsersClaims[claim]);
  return !!permission;
};

export const featuresUsingClaims = (userClaims?: Claim[]) => {
  const isActiveViaValue = activateViaValue(userClaims);
  const has = hasClaim(userClaims);

  return {
    hideCommissionDetails: isActiveViaValue('HIDE_DIVISION_DETAILS'),
    backoffice: isActiveViaValue('BACKOFFICE'),
    viewAllCommissions: isActiveViaValue('OFFICE_CREDITORS_VIEW'),
    viewTeamCommissions: isActiveViaValue('TEAM_CREDITORS_VIEW'),
    creditorNameOnRules: isActiveViaValue('FEATURE_CREDITOR_NAME_ON_RULES'),
    grossCommissionAmount: isActiveViaValue('GROSS_COMMISSION_AMOUNT'),
    allCommissionsTable: isActiveViaValue('ALL_COMMISSIONS_TABLE'),
    userAmountAgreement: isActiveViaValue('FEATURE_USER_AMOUNT_AGREEMENT'),
    makePayments: isActiveViaValue('PERMISSION_PAYMENTS'),
    goalsConfig: isActiveViaValue('FEATURE_GOALS_CONFIG'),
    customizedTable: has('CUSTOMIZED_TABLE'),
    adjustmentRequest: isActiveViaValue('ADJUSTMENT_REQUEST_VIEW'),
    documentManagement: has('DOCUMENT_MANAGEMENT'),
    paycheck: isActiveViaValue('PAYSLIP_VIEW'),
    variables: isActiveViaValue('FEATURE_VARIABLES'),
    teamVariablesView: isActiveViaValue('FEATURE_TEAM_VARIABLES_VIEW'),
    closureUpdatePerm: isActiveViaValue('CLOSURE_UPDATE'),
    applyRulesFuture: isActiveViaValue('APPLY_RULES_FUTURE_CLOSURES'),
    unblockPayment: isActiveViaValue('UNBLOCK_PAYMENT'),
    userImpersonation: isActiveViaValue('ALLOW_USER_IMPERSONATION'),
  };
};
